import {
  ISearchRequest,
  ClientSearchSDK,
  SearchDocumentType,
} from '@wix/client-search-sdk';

export const getSearchResponse = (
  searchRequest: ISearchRequest,
  shouldShowSamples: boolean,
  searchSDK: ClientSearchSDK,
) => {
  return shouldShowSamples
    ? Promise.resolve({
        documents: [],
        facets: [],
        documentType: SearchDocumentType.All,
        totalResults: 0,
      })
    : searchSDK.search(searchRequest);
};
