import {
  IWidgetController,
  IControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
import { createEventHandler } from '@wix/yoshi-flow-editor/tpa-settings';

import {
  ISearchResultsWixCode,
  IWidgetControllerConfig,
} from '../../../../lib/platform.types';
import { SearchResultsControllerStore } from './searchResultsControllerStore';
import { extractSettings } from './extractSettings';

interface ISettingsEvents {
  previewStateChange: {
    shouldHaveSearchResults: boolean;
    shouldSetNonAllDocumentType: boolean;
  };
}

export async function searchResultsControllerFactory(
  controllerConfig: IWidgetControllerConfig,
): Promise<IWidgetController> {
  const { config, reportError, getCategoryList, flowAPI } = controllerConfig;
  const categoryList = await getCategoryList();
  const { settings, publicData } = extractSettings(
    config,
    categoryList,
    flowAPI,
  );
  const eventHandler = createEventHandler<ISettingsEvents>(publicData);

  let controllerStore: SearchResultsControllerStore;
  eventHandler.on(
    'previewStateChange',
    (value: ISettingsEvents['previewStateChange']) => {
      controllerStore.updateDemoMode(value);
    },
  );

  eventHandler.onReset(() => {
    controllerStore.updateDemoMode({
      shouldHaveSearchResults: true,
      shouldSetNonAllDocumentType: false,
    });
  });

  try {
    controllerStore = new SearchResultsControllerStore(
      controllerConfig,
      settings,
    );
  } catch (error) {
    reportError?.(error);
    throw error;
  }

  return {
    pageReady() {
      return controllerStore.setInitialState();
    },

    updateConfig($w, updatedConfig) {
      const updatedSettings = extractSettings(
        updatedConfig as IControllerConfig,
        categoryList,
        flowAPI,
      );

      eventHandler.notify(updatedSettings.publicData);
      controllerStore.updateSettings(updatedSettings.settings);
    },

    exports: () =>
      ({
        // NOTE: used for mocks in tests
        searchSDK: controllerStore.getSearchSDK(),
        changeQuery: controllerStore.changeQuery,
      } as ISearchResultsWixCode),
  };
}
