import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  getSettingsValues,
  ISettingsValues,
} from '@wix/yoshi-flow-editor/tpa-settings';
import { CategoryList } from '@wix/search-settings-client';
import { IControllerConfig } from '@wix/native-components-infra/dist/src/types/types';

import settingsParams from '../../settingsParams';

export type Settings = ISettingsValues<typeof settingsParams> & {
  categoryList: CategoryList;
};

export const extractSettings = (
  config: IControllerConfig,
  categoryList: CategoryList,
  flowAPI: ControllerFlowAPI,
): {
  settings: Settings;
  publicData: object;
} => {
  const componentData = config.publicData.COMPONENT || {};
  const { isRTL, isMobile, language } = flowAPI.environment;
  const componentSettings = getSettingsValues(componentData, settingsParams, {
    t: flowAPI.translations.t,
    isRTL,
    isMobile,
    language,
  });

  const appData = config.publicData.APP || {};

  return {
    settings: { categoryList, ...componentSettings },
    publicData: { ...appData, ...componentData },
  };
};
