import { IWixAPI } from '@wix/native-components-infra/dist/src/types/types';

const DEFAULT_LANGUAGE = 'en';

export function getSiteLanguage(wixCodeApi: IWixAPI): string {
  const language = wixCodeApi.window.multilingual.isEnabled
    ? wixCodeApi.window.multilingual.currentLanguage
    : wixCodeApi.site.language;
  return language || DEFAULT_LANGUAGE;
}
