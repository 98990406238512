import {
  convertProductFacetsFilterToRequestParams,
  convertProductFacetsRequestParamsToFilter,
  isCollectionsSelected,
  isPriceSelectionChanged,
  shouldRequestFacet,
} from '../products';
import { getSearchResponse } from './getSearchResponse';
import { SearchParams } from './search';

export const getCollectionsFacetResponse = (searchParams: SearchParams) => {
  const { searchRequest, previousSearchResponse, searchSDK } = searchParams;

  const filter = convertProductFacetsRequestParamsToFilter(searchRequest);

  if (!isCollectionsSelected(filter)) {
    return Promise.resolve(undefined);
  }

  if (shouldRequestFacet(searchParams, [isPriceSelectionChanged])) {
    return getSearchResponse(
      {
        ...searchRequest,
        ...convertProductFacetsFilterToRequestParams({
          minPrice: filter.minPrice,
          maxPrice: filter.maxPrice,
        }),
        paging: {
          page: 0,
          pageSize: 0,
        },
      },
      false,
      searchSDK,
    );
  } else {
    return previousSearchResponse;
  }
};
