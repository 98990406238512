import {
  ILocationSearchRequest,
  IEncodedLocationSearchRequest,
} from '../location.types';

import { decoders } from './serializers';

export function decodeSearchRequest({
  q,
  qc,
  page,
  sort,
  price,
  collections,
}: IEncodedLocationSearchRequest): ILocationSearchRequest {
  const { minPrice, maxPrice } = decoders.decodeProductPrice(price);

  return {
    query: decoders.decodeQuery(q),
    ...(qc && { documentType: decoders.decodeDocumentType(qc) }),
    page: decoders.decodePageNumber(page),
    sortBy: decoders.decodeSortBy(sort),
    minPrice,
    maxPrice,
    collections: decoders.decodeProductCollections(collections),
  };
}
