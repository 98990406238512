import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { searchAppControllerFactory } from './platform/controllerFactory';
import extendConfig from '../../lib/controllerPropsMapper';

const createController: CreateControllerFn = async (props) => {
  const extendedConfig = extendConfig(props);
  return searchAppControllerFactory(extendedConfig);
};

export default createController;
