import { ISearchResponse, ISearchResponseTotals } from '@wix/client-search-sdk';

import {
  searchRequestStartedParams,
  searchRequestFinishedParams,
} from '@wix/bi-logger-wix-search-widget';

import { getBITotals } from './utils';
import { BiDocumentIds, CreateSearchRequestBI, SearchRequestBi } from './types';

export const createSearchRequestBI: CreateSearchRequestBI = ({
  biLogger,
  correlationId,
  isDemoContent,
  isEnabled,
  searchRequest,
}) => {
  let startTime: number;

  const biProps: searchRequestStartedParams | searchRequestFinishedParams = {
    correlationId,
    documentType: searchRequest.documentType,
    isDemo: isDemoContent,
    orderingDirection: searchRequest.ordering?.ordering?.[0]?.direction,
    orderingFieldName: searchRequest.ordering?.ordering?.[0]?.fieldName,
    target: searchRequest.query,
  };

  const started: SearchRequestBi['started'] = () => {
    if (!isEnabled) {
      return;
    }
    startTime = Date.now();
    // 99:303 searchResults - request was sent to server
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/303?artifactId=com.wixpress.wix-search-widget
    void biLogger.searchRequestStarted(biProps);
  };

  const logSearchRequestFinished = (
    p: Partial<searchRequestFinishedParams>,
  ) => {
    if (!isEnabled) {
      return;
    }
    // 99:304 SearchResults - request result was received
    // https://bo.wix.com/bi-catalog-webapp/#/sources/99/events/304?artifactId=com.wixpress.wix-search-widget
    void biLogger.searchRequestFinished({
      ...biProps,
      ...p,
      loadingDuration: Date.now() - startTime,
    });
  };

  const finished: SearchRequestBi['finished'] = (
    searchResponse: ISearchResponse,
    searchResponseTotals: ISearchResponseTotals,
    documentIds: BiDocumentIds,
  ) => {
    logSearchRequestFinished({
      documentIds: JSON.stringify(documentIds),
      resultCount: searchResponse.totalResults,
      resultsArray: getBITotals(searchResponseTotals),
      success: true,
    });
  };

  const failed: SearchRequestBi['failed'] = (error: Error) => {
    logSearchRequestFinished({
      error: error.toString(),
      success: false,
    });
  };

  return { started, finished, failed };
};
