import { parseUrl } from '@wix/native-components-infra/dist/src/urlUtils';
import { ClientSearchSDK, SearchDocumentType } from '@wix/client-search-sdk';
import { createSettingsClient } from '@wix/search-settings-client';
import { ControllerParams } from '@wix/yoshi-flow-editor';

import { createSearchLocation } from './location';
import { getSiteLanguage } from './getSiteLanguage';
import { Spec } from './specs';

const extendConfig = (props: ControllerParams) => {
  const { flowAPI, controllerConfig } = props;
  const { appParams, wixCodeApi, setProps } = controllerConfig;
  const { instance } = appParams;
  const { reportError, environment, experiments } = flowAPI;
  const siteBaseUrl = wixCodeApi.location.baseUrl;
  // NOTE: use default api url for editor environment
  // https://wix.slack.com/archives/CAKBA7TDH/p1568384247066100
  // https://sentry.io/organizations/wix_o/issues/1169886738/

  const apiBaseUrl =
    wixCodeApi.window.viewMode === 'Site' && siteBaseUrl
      ? getUrlOrigin(siteBaseUrl, reportError)
      : '';

  const settingsClient = createSettingsClient({
    instance,
    host: apiBaseUrl,
    reportError,
  });

  // searchBaseUrl is undefined when widget is rendered in editor.
  const searchAppSettings = apiBaseUrl
    ? settingsClient.getPublished()
    : settingsClient.getDefault();

  const getCategoryList = async () => {
    const { categoryList } = await searchAppSettings;
    return categoryList;
  };

  // TODO I think we can get language from `environment` and remove all this magic
  const language = getSiteLanguage(wixCodeApi);
  const searchLocation = createSearchLocation(wixCodeApi);

  const searchSDK = new ClientSearchSDK({
    baseURL: apiBaseUrl,
    language,
    siteBaseURL: siteBaseUrl,
    timeout: 25000,
    token: instance,
    useEmptyImages: experiments.enabled(Spec.UseEmptyImages),
    ...(!experiments.enabled(Spec.OnlinePrograms) && {
      excludeDocumentTypes: [SearchDocumentType.Programs],
    }),
  });

  const { isMobile, isSSR, isEditorX, isViewer } = environment;
  return {
    ...controllerConfig,
    experiments,
    getCategoryList,
    reportError,
    searchLocation,
    searchSDK,
    language,
    setProps,
    buildSearchAllUrl: async (searchQuery: string) => {
      const absoluteUrl = await searchLocation.getSearchResultsAbsoluteUrl();

      const searchAllRelativeUrl = searchLocation.encodeParams({
        query: searchQuery,
      });
      return `${absoluteUrl}/${searchAllRelativeUrl}`;
    },
    isMobile,
    isSSR,
    isEditorX,
    isViewer,
    flowAPI,
  };
};

export default extendConfig;

function getUrlOrigin(
  url: string,
  reportError: (error: Error) => void,
): string {
  const urlParsed = parseUrl(url);

  if (!urlParsed.protocol || !urlParsed.host) {
    reportError(new Error('missing protocol or host in parsed url'));
    return '';
  }

  return `${urlParsed.protocol}://${urlParsed.host}`;
}
